@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/accordion.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("./footer.css");

@media not (prefers-color-scheme: dark) {
	body {
		background-color: #8a8a8a;
	}
}

.share-targets.flex {
	gap: 12px;
}

#container-form {
	max-width: 1200px;
	padding-top: 2em;
	border-radius: 12px;
	transform: translatex(calc(50vw - 50%));
}

@media (max-width: 800px) {
	#container-form {
		width: 90%;
		transform: translatex(5%);
	}
}

.accordion > summary {
	border-radius: 30px;
}

body > button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: .8rem;
	font-size: 1.5rem;
}

#footer {
	background-color: #323232;
	color: #fafafa;
	padding: 2rem;
}
